// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

import "stylesheets/application";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import Viewer from "viewerjs";
import "viewerjs/dist/viewer.css";
import JSONFormatter from "json-formatter-js";
import UAParser from "ua-parser-js";
import { Chart } from "@antv/g2";
import "@hotwired/turbo-rails";
import { Turbo } from "@hotwired/turbo-rails";
Turbo.session.drive = false;

import "../controllers";

window.JSONFormatter = JSONFormatter;
window.UAParser = UAParser;
window.Chart = Chart;

document.addEventListener("turbolinks:load", function () {
  const elements = document.getElementsByClassName("request-images");
  if (elements.length) {
    for (let element of elements) {
      new Viewer(element, { navbar: false });
    }
  }
});
